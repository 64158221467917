
import { inject } from '@/inversify';
import { Mixins, Component } from 'vue-property-decorator';

import Item from '@/modules/common/interfaces/item.interface';
import DownloadReportForm, { DownloadReportControlMixin } from '@/modules/common/components/download-report-form.vue';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import DEFAULT_LOS from '@/modules/document-filters/constants/default-los.constant';
import MonthPicker from '@/modules/common/components/ui-kit/month-picker.vue';
import ProvidersService, { ProvidersServiceS } from '@/modules/providers/providers.service';
import MarketsService, { MarketsServiceS } from '../markets.service';
import { DownloadExcelForm } from '../markets-api.service';

@Component({
    components: {
        DownloadReportForm,
    },
})
export default class MarketsDownloadExcelModal extends Mixins(DownloadReportControlMixin) {
    @inject(CompsetsServiceS) private compsetsService!: CompsetsService;
    @inject(DocumentFiltersServiceS) private documentFiltersService!: DocumentFiltersService;
    @inject(MarketsServiceS) private marketsService!: MarketsService;
    @inject(ProvidersServiceS) private providersService!: ProvidersService;

    private isLoading = false;
    private form: Record<string, any> = {};

    get attrs() {
        const { customColumns, filters } = this;
        const { properties, isLoading } = this;
        const { buttons } = this;

        return {
            dataType: this.$tc('titles.markets'),
            customColumns,
            filters,
            properties,
            buttons,
            isLoading,
        };
    }

    async requestReport(form: DownloadExcelForm, toEmail?: boolean) {
        const month = form.date!.getMonth();
        const year = form.date!.getFullYear();
        const settings = {
            ...form,
            month,
            year,
            providers: form.choosedProviders.map(p => p.value),
            date: undefined,
        };

        this.isLoading = true;

        const providersWithoutReports = await this.marketsService
            .downloadExcel(settings, toEmail);

        if (providersWithoutReports.length) {
            const providerNames = providersWithoutReports
                .map(p => this.providersService.getProviderLabel(p))
                .join(', ');

            const message = this.$tc('err.nodatafor', 1, [providerNames]);
            this.triggerFormError(message);
        } else if (!toEmail) {
            this.closeForm();
        } else {
            this.triggerFormMessage(this.$tc('notifications.reportSent'));
        }

        this.isLoading = false;
    }

    private get properties() {
        const { month, year } = this.documentFiltersService;

        return [
            {
                label: this.$tc('date'),
                key: 'date',
                default: new Date(year, month),

                component: MonthPicker,
                props: {
                    locale: this.$i18n.locale,
                },
            },
        ];
    }

    private get customColumns() {
        return [
            {
                key: 'three_days_history',
                label: this.$t('popup.daysAgo', [3]),
                default: true,
            },
            {
                key: 'seven_days_history',
                label: this.$t('popup.daysAgo', [7]),
                default: true,
            },
            {
                key: 'fourteen_days_history',
                label: this.$t('popup.daysAgo', [14]),
                default: true,
            },
        ];
    }

    private get compsetOptions() {
        return (this.compsetsService.compsets || [])
            .map(compset => ({
                name: compset.name,
                value: compset.id,
            }));
    }

    private get losOptions() {
        return DEFAULT_LOS.map(los => ({
            value: los,
            name: this.$tc('filters.los.num', 0, [los]),
        }));
    }

    private get posOptions() {
        return this.documentFiltersService.posMarketItems;
    }

    private get filters() {
        const { id: currentCompsetId = null } = this.compsetsService.currentCompset || {};
        const { pos, los } = this.documentFiltersService.settings;

        const compset = this.compsetsService.getCompset(this.form.compsetId)
            || this.compsetsService.currentCompset;

        const providerList = compset?.marketProviders || [];
        const providerItems = this.providersService.toItemsList(providerList);

        const defaultSource = providerItems[0];

        this.form.choosedProviders = (this.form.choosedProviders || [])
            .filter((p: Item<string>) => providerList.includes(p.value));

        return [
            {
                label: this.$tc('titles.compset'),
                options: this.compsetOptions,
                key: 'compsetId',
                default: currentCompsetId,
            },
            {
                label: this.$tc('titles.provider'),
                options: providerItems,
                key: 'choosedProviders',
                default: defaultSource ? [defaultSource] : [],
                multiselect: true,
            },
            {
                label: this.$tc('titles.los'),
                options: this.losOptions,
                key: 'los',
                default: los,
            },
            {
                label: this.$tc('titles.pos'),
                options: this.posOptions,
                key: 'pos',
                default: pos,
            },
        ];
    }

    private get buttons() {
        return [
            {
                label: this.$tc('popup.download'),
                onClick: this.requestReport.bind(this),
            },
        ];
    }
}
